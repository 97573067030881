import React from "react"
import HeaderV2 from "../headerv2/headerv2"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"
import HeroNoImage from "../../microcomponents/heronoimage/heronoimage"
import Image from "../../components/image-component/image-new"
import LeftRightImageText from "../../microcomponents/leftrightimagetext/leftrightimagetext"
import { testimonials } from "../../utilities/testimonials"
import Modal from "../../components/modal/modal"
import YouTube from "react-youtube"
import "./testimonialsv2.scss"
import { PlayArrow, PlayIcon, WatchIcon } from "../../microcomponents/icons"

export default function TestimonialsV2() {
  return (
    <div>
      <HeaderV2 version="v2" />
      {/* <h1>TestimonialsV2</h1> */}

      <HeroNoImage
        className="honey purpleeclipse"
        heading="Testimonials"
        description="<p>See how high-growth brands are <br class='hidedesktop hidetablet hideipad'/> beating plans, crushing <br class='hidemobile'/> competitors<br class='hidedesktop hidetablet hideipad'/> and having fun doing it.</p>"
        component={null}
      />

      {testimonials?.map((item, index) => (
        <LeftRightImageText
          mainHeading=""
          sectionDescription=""
          key={index}
          backgroundImage={item.bgPattern}
          innerClasses={`pd-112 ${index % 2 ? "reverse" : ""} testimonilspage`}
          imageComponent={
            <div className="video-wrapper">
              <Modal
                modalButton={
                  <span className="hover-btn" style={{ cursor: "pointer" }}>
                    <Image
                      sourceFolder={"spiralyze2024website"}
                      className="video zoomonhover"
                      cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/"
                      imgName={item.videoPlaceholder.responsiveImages}
                      fallBackImage={item.videoPlaceholder.fallBackImage}
                      alt={item.placeholderAlt}
                    />
                    <div className="playshowonhover">
                      <PlayIcon />
                    </div>

                    {/* <button className="youtube-button">
                      <Image
                        sourceFolder={"testimonials"}
                        imgName={item.yutubeButton}
                        alt={"Youtube Button"}
                      />
                    </button> */}
                    <div className="video-info">
                      <div className="testimonial-play-btn d-flex">
                        {/* <PlayArrow /> */}
                        <WatchIcon />
                        <span>{item.videoLength}</span>
                      </div>
                    </div>
                  </span>
                }
                modalContent={
                  <div className="iframe-wrap">
                    <YouTube videoId={item.videoID} />
                  </div>
                }
              />
            </div>
          }
        >
          <div className="client-information-box">
            <Image
              sourceFolder={"testimonialsnew"}
              className="logo hidetablet hideipad hidemobile"
              imgName={item.logo}
              alt={item.alt}
            />
            <p>{item.testimonialDescription}</p>
            <div className="client-position-wrapper">
              <div className="client-name-desig">
                <h3>{item.personName}</h3>
                <p>{item.personDesignation}</p>
              </div>
              <div className="hidedesktop">
                <Image
                  sourceFolder={"testimonialsnew"}
                  className="logo"
                  imgName={item.logo}
                  alt={item.alt}
                />
              </div>
            </div>
          </div>
        </LeftRightImageText>
      ))}

      <TopPredictions version="v2" />
      <ClientResults version="v2" />
    </div>
  )
}
