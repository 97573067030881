// ”

export const testimonials = [
  {
    id: "okta",
    logo: "oktanew.png",
    blueLogo: {
      fallBackImage: "okta_blue_logo.png",
      responsiveImages: [
        {
          imageName: "okta_blue_logo.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "okta_blue_logo_tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "okta_blue_logo_mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "Okta Logo",
    testimonialDescription:
      "“Through a number of page redesign tests we've seen test lifts between 40-90%.”",
    personName: "Rodolfo Yiu",
    personDesignation: "Sr. Manager of Digital Marketing",
    ctaURL: "",
    ctaText: "See time doctor Case Study",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-01_1.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-01_1.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "rodolfo-yiu-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "Okta Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "6 min",
    videoID: "xv3XXP_Tosg",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/blue.webp",
  },
  {
    id: "record360",
    logo: "record360new.png",
    blueLogo: {
      fallBackImage: "ideal_image_blue_logo.png",
      responsiveImages: [
        {
          imageName: "ideal_image_blue_logo.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "ideal_image_blue_logo_tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "idealimage_blue_logo_mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "Record360 Logo",
    testimonialDescription:
      "“Working with Spiralyze has been incredible! The entire team is really knowledgeable. We've seen an increase in paid traffic and the quality of leads has improved. If things continue how they are - it's gonna have an amazing impact on our business.”",
    personName: "Emilia Pintos",
    personDesignation: "Director of Marketing",
    ctaURL: "",
    ctaText: "See Record360 Case Study",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-02_1.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-02_1.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "brian-stetter-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "Record360 Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "2 min",
    videoID: "y4Onk1uFll0",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/yellow.webp",
  },
  {
    id: "workday",
    logo: "workdaynew.png",
    blueLogo: {
      fallBackImage: "workday_blue_logo.png",
      responsiveImages: [
        {
          imageName: "workday_blue_logo.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "workday_blue_logo_tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "workday_blue_logo_mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "Workday Logo",
    testimonialDescription:
      "“Working with Spiralyze, for Adaptive Insights, has been fantastic. They have truly been an extension of our team, they have helped us improve our conversion rates, we have driven more leads, we have beaten plan, and we have helped build a lot of pipeline.”",
    personName: "Michael Freeman",
    personDesignation: "VP of Demand Marketing",
    ctaURL: "",
    ctaText: "See Workday Case Study",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-04_1.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-04_1.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "michael-freeman-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "Workday Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "2 min",
    videoID: "2uQsuv_1tX0",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/red.webp",
  },
  {
    id: "podium",
    logo: "podiumnew.png",
    blueLogo: {
      fallBackImage: "podium_blue_logo.png",
      responsiveImages: [
        {
          imageName: "podium_blue_logo.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "podium_blue_logo_tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "podium_blue_logo_mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "Podium Logo",
    testimonialDescription:
      "“If we even improve conversion on our page by 1% or 2%, it would pay for the Spiralyze contract. Most of the tests we've run have improved conversion rates by 20-50% and they have tests mapped out for the rest of the year. It is not even close to what we could've done on our own and is significantly better.”",
    personName: "Matt Boyce",
    personDesignation: "Sr. Director of Demand Generation",
    ctaURL: "",
    ctaText: "See time doctor Case Study",
    videoPlaceholder: {
      fallBackImage: "review-thumb_1.webp",
      responsiveImages: [
        {
          imageName: "review-thumb_1.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "matt-boyce-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "Podium Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "5 min",
    videoID: "G-55Zm8r57s",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/gray.webp",
  },
  {
    id: "five9",
    logo: "fiveninenew.png",
    blueLogo: {
      fallBackImage: "five9_blue_logo.png",
      responsiveImages: [
        {
          imageName: "five9_blue_logo.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "five9_blue_logo_tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "five9_blue_logo_mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "Five9 Logo",
    testimonialDescription:
      "“Spiralyze helps us turn thousands of website visitors into leads for our sales force. They have tremendous experience and great suggestions.”",
    personName: "Jason Yang",
    personDesignation: "VP of Digital Marketing",
    ctaURL: "",
    ctaText: "See THE FIVE9 Case Study",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-06_2.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-06_2.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "jason-yang-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "Five9 Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "2 min",
    videoID: "YdkdALKm37w",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/blue.webp",
  },
  {
    id: "progress",
    logo: "progressnew.png",
    blueLogo: {
      fallBackImage: "progress_blue_logo.png",
      responsiveImages: [
        {
          imageName: "progress_blue_logo.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "progress_blue_logo_tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "progress_blue_logo_mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "Progress Logo",
    testimonialDescription:
      "“We saw a 125% increase in conversion rate for demo requests.”",
    personName: "Megan Gouveia",
    personDesignation:
      "Sr. Manager of Digital Marketing, Personalization & Optimization",
    ctaURL: "",
    ctaText: "See Progress Case Study",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-07_2.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-07_2.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "megan-gouveia-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "Progress Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "3 min",
    videoID: "oETkNoINKCQ",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/green.webp",
  },
  {
    id: "timedoctor",
    logo: "timedoctornew.png",
    blueLogo: {
      fallBackImage: "timedoctor_blue_logo.png",
      responsiveImages: [
        {
          imageName: "timedoctor_blue_logo.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "timedoctor_blue_logo_tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "timedoctor_blue_logo_mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "TimeDoctor Logo",
    testimonialDescription:
      "“Now 50% more is actually converting and getting revenue for us so it’s absolutely huge.”",
    personName: "Rob Rawson",
    personDesignation: "CEO",
    ctaURL: "",
    ctaText: "See time doctor Case Study",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-08_2.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-08_2.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "rob-rawson-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "TimeDoctor Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "1 min",
    videoID: "WridNRgdLS8",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/red.webp",
  },
  {
    id: "servicemax",
    logo: "servicemaxnew.png",
    blueLogo: {
      fallBackImage: "servicemax_blue_logo.png",
      responsiveImages: [
        {
          imageName: "servicemax_blue_logo.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "servicemax_blue_logo_tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "servicemax_blue_logo_mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "ServiceMax Logo",
    testimonialDescription:
      "“We had aggressive KPI targets, and I’d have to say they’ve delivered on all points. On our landing pages, we’ve seen over a 30% conversion rate increase. We have a lot of exciting initiatives for the next year and Spiralyze is at the core of a lot of these initiatives.”",
    personName: "Pat Oldenburg",
    personDesignation: "VP of Demand Marketing & Ops",
    ctaURL: "",
    ctaText: "See ServiceMax Case Study",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-09_2.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-09_2.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "pat-oldenburg-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "ServiceMax Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "4 min",
    videoID: "9LBXrVZsiXA",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/green.webp",
  },
  {
    id: "digitalguardian",
    logo: "digitalguardiannew.png",
    blueLogo: {
      fallBackImage: "digitalguardian_blue_logo.png",
      responsiveImages: [
        {
          imageName: "digitalguardian_blue_logo.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "digital_guardian_blue_logo_tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "digitalguardian_blue_logo_mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "Digital Guardian Logo",
    testimonialDescription:
      "“For all of 2020, we actually increased our year-over-year website conversion rate by 50%, and we not only increased the conversion volume but the conversion quality.”",
    personName: "Brian Mullins",
    personDesignation: "VP of Digital and Product Marketing",
    ctaURL: "",
    ctaText: "See time doctor Case Study",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-10_2.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-10_2.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "brian-mullins-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "Digital Guardian Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "3 min",
    videoID: "cP3CWxHmGSM",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/purple.webp",
  },
  {
    id: "latka",
    logo: "latkanew.png",
    blueLogo: {
      fallBackImage: "latka_blue_logo.png",
      responsiveImages: [
        {
          imageName: "latka_blue_logo.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "latka_blue_logo_tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "latka_blue_logo_mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "Nathan Latka Logo",
    testimonialDescription:
      "“The impact that Spiralyze has had has basically been going from $10K-20K per month in sales on the magazine landing page to $30K-50K in sales per month on the landing page. It’s a material difference, and I’m excited to give them more and larger projects in the future.”",
    personName: "Nathan Latka",
    personDesignation: "Founder, Author, & Investor",
    ctaURL: "",
    ctaText: "See time doctor Case Study",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-11_2.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-11_2.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "nathan-latka-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "Nathan Latka Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "6 min",
    videoID: "mrLM14toyXs",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/blue.webp",
  },
  {
    id: "promscent",
    logo: "promescentnew.png",
    blueLogo: {
      fallBackImage: "promscent_blue_logo.png",
      responsiveImages: [
        {
          imageName: "promscent_blue_logo.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "promscent_blue_logo_tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "promscent_blue_logo_mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "Promescent Logo",
    testimonialDescription:
      "“They got up 3 tests in a matter of 3 weeks and one of those ended up winning by 30%.”",
    personName: "Ryan Kellner",
    personDesignation: "Director of Marketing",
    ctaURL: "",
    ctaText: "See time doctor Case Study",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-12_1.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-12_1.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "ryan-kellner-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "Promescent Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "4 min",
    videoID: "zUm4ipoEr90",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/yellow.webp",
  },
  {
    id: "ubersuggest",
    logo: "ubersuggestnew.png",
    blueLogo: {
      fallBackImage: "ubersuggest_blue_logo.png",
      responsiveImages: [
        {
          imageName: "ubersuggest_blue_logo.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "ubersuggest_blue_logo_tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "ubersuggest_blue_logo_mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "Ubersuggest Logo",
    testimonialDescription:
      "“Spiralyze has run over 60 tests on Ubersuggest. My revenue has doubled. It has been well worth it!”",
    personName: "Neil Patel",
    personDesignation: "Founder",
    ctaURL: "",
    ctaText: "See time doctor Case Study",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-13_2.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-13_2.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "neil-patel-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "Ubersuggest Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "4 min",
    videoID: "rvRAwbs_s48",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/green.webp",
  },
  {
    id: "rstudio",
    logo: "rstudionew.png",
    blueLogo: {
      fallBackImage: "rstudio_blue_logo.png",
      responsiveImages: [
        {
          imageName: "rstudio_blue_logo.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "rstudio_blue_logo_tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "rstudio_blue_logo_mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "RStudio Logo",
    testimonialDescription:
      "“Spiralyze really jumpstarted the whole website testing program and process for us. They increased exponentially our qualified pro-conversions. More importantly, they acted as an extension of our team.”",
    personName: "Bill Carney",
    personDesignation: "Director of Marketing",
    ctaURL: "",
    ctaText: "See The R-STUDIO Case Study",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-14_2.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-14_2.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "bill-carney-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "RStudio Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "2 min",
    videoID: "8RnNZpui6vw",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/purple.webp",
  },
  {
    id: "nurturelife",
    logo: "nurturelifenew.png",
    blueLogo: {
      fallBackImage: "logo-nurture-life-blue.png",
      responsiveImages: [
        {
          imageName: "logo-nurture-life-blue.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "logo-nurture-life-blue-tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "logo-nurture-life-blue-mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "Nurture Life Logo",
    testimonialDescription:
      "“Spiralyze has had a really big impact on Nurture Life. When we first started working with them our conversion rate was relatively flat and since the beginning of our engagement, we've seen a steady upward increase.”",
    personName: "Amy Rose Carle",
    personDesignation: "Director of Operations Excellence",
    ctaURL: "",
    ctaText: "",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-15_2.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-15_2.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "amy-rose-carle-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "Nurture Life Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "2 min",
    videoID: "_JuuAIc8jyc",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/green.webp",
  },
  {
    id: "caroo",
    logo: "caroonew.png",
    blueLogo: {
      fallBackImage: "logo-caroo-blue.png",
      responsiveImages: [
        {
          imageName: "logo-caroo-blue.png",
          minBreakpoint: "992px",
        },
        {
          imageName: "logo-caroo-blue-tablet.png",
          minBreakpoint: "768px",
        },
        {
          imageName: "logo-caroo-blue-mobile.png",
          minBreakpoint: "320px",
        },
      ],
    },
    alt: "Caroo Logo",
    testimonialDescription:
      "“Spiralyze professionalized our CRO testing process which gave us a higher conversion rate through our funnels, giving us more MQLs, more leads to our sales team, and more revenue.”",
    personName: "Andy Mackensen",
    personDesignation: "Co-Founder & CMO",
    ctaURL: "",
    ctaText: "",
    videoPlaceholder: {
      fallBackImage: "review-thumb-desktop-16_2.webp",
      responsiveImages: [
        {
          imageName: "review-thumb-desktop-16_2.webp",
          minBreakpoint: "768px",
        },
        // {
        //   imageName: "andy-mackensen-mobile.webp",
        //   minBreakpoint: "320px",
        // },
      ],
    },
    placeholderAlt: "Caroo Video",
    yutubeButton: "play-youtube-2023.svg",
    videoLength: "3 min",
    videoID: "gDLewKa32Sg",
    bgPattern:
      "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/yellow.webp",
  },
]
